/* react-datepicker */
.react-datepicker-wrapper {
  width: 100%;
}

/* react select */
.react-select__control {
  border-color: rgb(156, 163, 175, 1) !important;
}

.react-select__value-container {
  padding: 4px 16px !important;
}

/* react-recaptcha */
.g-recaptcha div {
  margin: 0 auto;
}
